import axios from 'axios'

export default {
  actions: {


    async addFile({ commit }, { id, type, name, data }) {
      try {
        const url = `/api/files/add/?o_id=${id}&o_t=${type}&n=${name}`;

        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data", // Укажите правильный заголовок, если отправляете файлы
          },
        });
    
        return response; // Возвращаем результат
      } catch (error) {
        throw error;
      }
    }
    

  },  
}