import axios from 'axios'

export default {
  actions: {

    async getChat({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/messages/', {
            
            method: 'POST',
            data: data,
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async addFileChat({ commit }, { id, type, name, typeChat, formData }) {
      try {
        const url = `/api/files/add/?o_id=${id}&o_t=${type}&n=${name}&is_message=1&is_second_chat=${typeChat}`;
        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Указываем, что отправляем файл
          },
        });
        return response; // Возвращаем данные ответа
      } catch (error) {
        console.error("Ошибка при отправке файла:", error.response?.data || error.message);
        throw error; // Пробрасываем ошибку дальше для обработки
      }
    }
    
    
  }
}